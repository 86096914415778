const $window = window
import Vue from 'vue'
Vue.prototype.$window = $window
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify';
import 'axios-progress-bar/dist/nprogress.css'
import axios from "axios";
import i18n from '@/i18n'
import _ from 'lodash';
import VuePlyr from 'vue-plyr'
import * as core from "@/core/index";
import VueKonva from 'vue-konva'
import './plugins/vue-auth'
import moment from 'moment'
import VueEcho from 'vue-echo-laravel';
// import * as PusherPushNotifications from "@pusher/push-notifications-web";
import * as Sentry from "@sentry/vue"
import { getCookie } from "@/helpers/functions";
import VueMeta from 'vue-meta'

Object.defineProperty(Vue.prototype, '$_', { value: _ });

// PLUGINS
import '@/plugins/vue2-transitions';
import VueMask from 'v-mask'
import mixpanel from './plugins/mixpanel';

Vue.use(VueMask);

Vue.use(VueKonva)
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
})

// GLOBAL COMPONENTS
import GlobalComponents from "./globalComponents";
Vue.use(GlobalComponents);

// STYLES
// App SCSS
import '../src/sass/app.scss'

// Animation library - https://animate.style/
import 'animate.css/animate.css'

Vue.use(VuePlyr,  {plyr: {}})
Vue.use(require('vue-pusher'), {
    api_key: process.env.VUE_APP_PUSHER_APP_KEY,
    options: {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        encrypted: true,
    }
});

Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    auth: {
        headers: {
            Authorization: `Bearer ${getCookie('auth_token_default')}`
        },
    },
    authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
});

// Sentry integration
Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/opadjusting this value in productiontions/#traces-sample-rate
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/noodzly\.com/,
        /^https:\/\/staging\.noodzly\.com/
    ],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    environment: process.env.VUE_APP_SENTRY_ENV,
  });

Vue.config.productionTip = false
require('./bootstrap');

/*
const $beamsClient = new PusherPushNotifications.Client({
    instanceId: process.env.VUE_APP_PUSHER_BEAMS_INSTANCE_ID,
})
$beamsClient.start()
    .then(() => {
        $beamsClient.addDeviceInterest('debug-information')
    })
    .catch(console.error)
*/

Vue.prototype.$core = core;
// Vue.prototype.$beamsClient = $beamsClient
Vue.prototype.$rQ = core.requestCore;
Vue.prototype.$endpoint = 'https://localhost/api/';
Vue.prototype.$axios = axios;
Vue.prototype.moment = moment;
Vue.prototype.moment.locale(localStorage.getItem('noodzly_lang') || '')
Vue.prototype.$log = console.log;
Vue.prototype.$mixpanel = mixpanel;
Vue.use(VueMeta)
new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app')
